import { CustomerType, StatusCode, VisibilityType } from '@alcon-db-models/Enums';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridComponent } from '@progress/kendo-angular-grid';
import { AppWindowService } from '@services/app-window.service';
import { CustomerSearchService } from '@services/customer-search.service';
import { PageExpansionService } from '@services/page-expansion.service';
import { SearchFilterService } from '@services/search-filter.service';
import { Observable, combineLatest, of } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';
import { CustomerSearchBindingDirective } from 'src/app/directives/customer-search-binding.directive';
import { UserPreferenceEnabledDataBindingDirective } from 'src/app/directives/user-preference-enabled-data-binding.directive';
import { CustomerViewMode, SearchFlavor } from 'src/app/shared/static';
import { UserPreferenceEnabledGridBaseComponent } from '../user-preference-enabled-grid.base.component';
import { CustomerWithDetailsService } from '@services/customer-with-details.service';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { CustomerSearchWithDefaultModel } from '@alcon-db-models/CustomerSearchWithDefaultModel';
import { CustomerSearchModel } from '@alcon-db-models/CustomerSearchModel';
import { AppUxService } from '@services/app-ux.service';
import { CustomerInfoService } from '@services/customer-info.service';

@Component({
  selector: 'acb-alcon-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent extends UserPreferenceEnabledGridBaseComponent implements OnInit {

  @ViewChild('resultsGrid') public resultsGrid?: GridComponent;

  private _bindingDirective?: CustomerSearchBindingDirective;
  @ViewChild(CustomerSearchBindingDirective) public set bindingDirective(value: CustomerSearchBindingDirective | undefined) {
    this._bindingDirective = value;
    if (this._bindingDirective) this._bindingDirective.preferenceKey = "CustomerSearch_" + this.searchFlavor;
  }
  public get bindingDirective() { return this._bindingDirective; }

  @Input() searchFlavor: SearchFlavor = 'search';
  @Input() customerViewMode: CustomerViewMode = 'customer'

  public yesNoList: { name: string, value: boolean | undefined }[] = [
    { name: '', value: undefined }
  , { name: 'Yes', value: true }
  , { name: 'No', value: false }
  ];
  public yesNoVisibilityList: { name: string, value: VisibilityType | undefined }[] = [
    { name: '', value: undefined }
  , { name: 'Yes', value: VisibilityType.Visible }
  , { name: 'No', value: VisibilityType.Hidden }
  ];

  public yesVisibility = { name: 'Yes', value: VisibilityType.Visible };

  public activateableSelectedKeys: number[] = [];
  public deactivateableSelectedKeys: number[] = [];
  public selectedItems: CustomerSearchModel[] = []; 

  public payTypes$: Observable<StaticTypeModel[]>;
  public stateProvinceCodes$: Observable<StaticTypeModel[]>;
  public countryCodes$: Observable<StaticTypeModel[]>;
  public customerTypes$: Observable<StaticTypeModel[]>;
  public exportFileName: string = "Customers.xlsx";
  
  public visibleVisibilityTypeID: VisibilityType = VisibilityType.Visible;
  public hiddenVisibilityTypeID: VisibilityType = VisibilityType.Hidden;

  formDefaults = {
    customerIDs: null,
    customerTypeID: null,
    isACHExcluded: null,
    isACHEnrolled: null,
    customerCode: null,
    customerName: null,
    emailAddress: null,
    location: null,
    city: null,
    stateProvinceCodeID: null,
    postalCode: null,
    countryCodeID: null,
    taxCode: null,
    payTypeID: null,
    visibilityTypeID: VisibilityType.Visible,
  }

  constructor(
    store: Store,
    pageExpansionService: PageExpansionService,
    searchFilterService: SearchFilterService,
    router: Router,
    changeDetectorRef: ChangeDetectorRef,
    private appWindowService: AppWindowService,
    private customerSearchService: CustomerSearchService,
    private customerWithDetailsService: CustomerWithDetailsService,
    private appUxService: AppUxService,
    private customerInfoService: CustomerInfoService    
    ) {
      super(store, pageExpansionService, changeDetectorRef, searchFilterService, router)

      this.searchForm = new UntypedFormGroup({
        customerIDs: new UntypedFormControl(),
        customerTypeID: new UntypedFormControl(),
        isACHExcluded: new UntypedFormControl(),
        isACHEnrolled: new UntypedFormControl(),
        customerCode: new UntypedFormControl(),
        customerName: new UntypedFormControl(),
        emailAddress: new UntypedFormControl(),
        location: new UntypedFormControl(),
        city: new UntypedFormControl(),
        stateProvinceCodeID: new UntypedFormControl(),
        postalCode: new UntypedFormControl(),
        countryCodeID: new UntypedFormControl(),
        taxCode: new UntypedFormControl(),
        payTypeID: new UntypedFormControl(),
        visibilityTypeID: new UntypedFormControl(),
      });

      this.customerTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'CustomerType') as StaticTypeModel[]));
      // this.payTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'PayType') as StaticTypeModel[]));
      this.payTypes$ = this.staticTypes$.pipe(map(x => ([...[{ id: null, displayName: '' }], ...x.filter(y => y.tableName == 'PayType')] as StaticTypeModel[])));
      this.stateProvinceCodes$ = this.staticTypes$.pipe(map(x => ([...[{ id: null, displayName: '' }], ...x.filter(y => y.tableName == 'StateProvinceCode')] as StaticTypeModel[])));
      this.countryCodes$ = this.staticTypes$.pipe(map(x =>  ([...[{ id: null, displayName: '' }], ...x.filter(y => y.tableName == 'CountryCode')] as StaticTypeModel[])));

      this.searchForm.reset(this.formDefaults);
  }

  public allData(): Observable<ExcelExportData> {
    return this.bindingDirective ?
      this.customerSearchService.searchForExcelExport(this.bindingDirective.getSearchRequest(true) ?? {}) :
      of({});
  }

  ngOnInit(): void {
    super.ngOnInit();

    let customerTypeID;
    switch (this.customerViewMode) {
      case 'thirdparty':
        this.exportFileName = "ThirdPartyPayees.xlsx";
        customerTypeID = CustomerType.AlternativePayee;
        break;
      case 'payee':
        this.exportFileName = "Payees.xlsx";
        break;
      case 'customer':
        this.exportFileName = "Customers.xlsx";
        (this.searchForm as UntypedFormGroup)?.patchValue({ customerTypeID: CustomerType.Hierarchy});
        break;
    }
    if (customerTypeID)
      (this.searchForm as UntypedFormGroup)?.patchValue({ customerTypeID: customerTypeID });
  }

  public onCreateNewCustomer() {
    const dialogSpec = this.appWindowService.openCreatePayee(this.customerViewMode);
    dialogSpec?.component?.cancel?.pipe(take(1)).subscribe(() => dialogSpec?.dialogRef.close());
    dialogSpec?.component?.saved?.pipe(take(1)).subscribe(x => {
      dialogSpec?.dialogRef.close();
      this.rebind();
    });
  }

  public onEditCustomer = ((event: any, dataItem: any ) =>  {
    if (!dataItem?.customerID)
      return;
    const dialogSpec = this.appWindowService.openEditPayee(this.customerViewMode, dataItem.customerID);
    dialogSpec?.component?.cancel?.pipe(take(1)).subscribe(() => dialogSpec?.dialogRef.close());
    dialogSpec?.component?.saved?.pipe(take(1)).subscribe(x => {
      dialogSpec?.dialogRef.close();
      this.rebind();

    });
    this.selectedKeys = [dataItem?.customerID];
  }).bind(this);

  public onActivateSelected = (() => {
    this.updateStatusOfSelection(VisibilityType.Visible)
  }).bind(this);

  public onDeactivateSelected = (() => { 
    this.appWindowService.openConfirmation(
      "Deactivate Customers",
      `Are you sure you wish deactivate the ${this.deactivateableSelectedKeys.length} selected customer${this.deactivateableSelectedKeys.length > 1 ? "s" : ""}?`,
      undefined,
      190,
      'alcon-background-orange',
      'acb-section-09').then((confirm) => {
        if (confirm) this.updateStatusOfSelection(VisibilityType.Hidden);
      });   
   }).bind(this);

  private updateStatusOfSelection(visibilityType: VisibilityType) {
    combineLatest(this.selectedKeys.map(x =>
      this.customerInfoService.getByKey(x).pipe(first(), switchMap(result => {
        if (result = JsonUtilities.convertDatesAndCopy(result)) {
          result.visibilityTypeID = visibilityType;
          return this.customerInfoService.upsert(result).pipe(first());
        }
        return of(null);
      }))
    )).subscribe(() => {
      this.selectedItems = [];
      this.selectedKeys = [];
      this.rebind();
    });
  }

  public onSelectedChange() {
    const boundItems: CustomerSearchWithDefaultModel[] = this.bindingDirective?.customerSearchService?.value?.data ?? [];
    this.selectedItems = [
      ...this.selectedItems.filter(x => x.customerID ? this.selectedKeys.includes(x.customerID) : false),
      ...this.selectedKeys.filter(x => !this.selectedItems.some(y => y.customerID == x)).map(x => boundItems.find(y => y.customerID == x) ?? {}).filter(x => x != null)
    ]
    this.activateableSelectedKeys = this.selectedItems.filter(x => x.customerTypeID == CustomerType.AlternativePayee && x.visibilityTypeID != VisibilityType.Visible && x.customerID).map(x => x.customerID ?? 0);
    this.deactivateableSelectedKeys = this.selectedItems.filter(x => x.customerTypeID == CustomerType.AlternativePayee && x.visibilityTypeID != VisibilityType.Hidden && x.customerID).map(x => x.customerID ?? 0);
  }

  public onClearButtonClick() {
    this.selectedKeys = [];
  } 

}
